import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  TextBox,
  CardItemContainer,
  BottomBox,
  CustomDialogTitle,
  SubTextBox,
} from "./styles";
import {
  Button,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  Grid2,
  Typography,
} from "@mui/material";
import axios from "../service/AxiosBaseService";
import { useAPI } from "../service/api";

export interface Coupon {
  id: number;
  coupon_name: string;
  discount: string;
  description: string;
  details: string;
  image: string;
  use_point: string;
}

interface UserCoupon {
  id: number;
  user: number;
  users_coupon: Coupon;
  is_used: boolean;
  used_at: string | null;
}

const CouponItem = ({
  userCoupon,
  onCouponUsed,
}: {
  userCoupon: UserCoupon;
  onCouponUsed: () => void;
}) => {
  const api = useAPI();
  const [open, setOpen] = useState(false);
  const [openCheck, setOpenCheck] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckOpen = () => {
    setOpenCheck(true);
  };

  const handleCheckClose = () => {
    setOpenCheck(false);
  };

  const handleCheckCoupon = () => {
    try {
      handleCheckOpen();
      handleClose();
    } catch (error: any) {
      console.error("クーポン使用エラー:", error);
      alert("クーポンの使用に失敗しました。");
    }
  };

  const handleUseCoupon = async () => {
    try {
      const response = await axios.patch(`/user-coupon/${userCoupon.id}`);

      if (response.status === 200 || response.status === 204) {
        onCouponUsed();
        handleCheckClose();
      } else {
        console.error(
          "クーポンの使用に失敗しました:",
          response.status,
          response.data
        );
        alert("クーポンの使用に失敗しました。再試行してください。");
      }
    } catch (error: any) {
      console.error("クーポン使用エラー:", error);
      alert("クーポンの使用に失敗しました。");
    }
  };
  return (
    <>
      <CardItemContainer onClick={handleClickOpen}>
        {" "}
        <CardMedia
          component="img"
          image={userCoupon.users_coupon.image}
          alt="Paella dish"
        />
        <CardActionArea sx={{ display: "flex" }}>
          <CardContent>
            <Typography variant="h6" component="div">
              {userCoupon.users_coupon.coupon_name}
            </Typography>
            <Typography variant="body2">
              {userCoupon.users_coupon.discount}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {userCoupon.users_coupon.details}
            </Typography>
          </CardContent>
        </CardActionArea>
      </CardItemContainer>

      <Dialog open={open} onClose={handleClose}>
        <CustomDialogTitle>クーポンの使用</CustomDialogTitle>
        <DialogContent sx={{ margin: "0px 20px" }}>
          <CardItemContainer>
            {" "}
            <CardMedia
              component="img"
              image={userCoupon.users_coupon.image}
              alt="Paella dish"
            />
            <CardActionArea sx={{ display: "flex" }}>
              <CardContent>
                <Typography variant="h6" component="div">
                  {userCoupon.users_coupon.coupon_name}
                </Typography>
                <Typography variant="body2">
                  {userCoupon.users_coupon.discount}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {userCoupon.users_coupon.details}
                </Typography>
              </CardContent>
            </CardActionArea>
          </CardItemContainer>
          <Typography sx={{ marginTop: "10px" }}>
            {userCoupon.users_coupon.coupon_name}を使用しますか？
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            キャンセル
          </Button>
          <Button variant="contained" onClick={handleCheckCoupon} autoFocus>
            使用
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openCheck} onClose={handleCheckClose}>
        <CustomDialogTitle>クーポン使用中</CustomDialogTitle>
        <DialogContent sx={{ margin: "0px 20px" }}>
          <CardItemContainer>
            {" "}
            <CardMedia
              component="img"
              image={userCoupon.users_coupon.image}
              alt="Paella dish"
            />
            <CardActionArea sx={{ display: "flex" }}>
              <CardContent>
                <Typography variant="h6" component="div">
                  {userCoupon.users_coupon.coupon_name}
                </Typography>
                <Typography variant="body2">
                  {userCoupon.users_coupon.discount}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {userCoupon.users_coupon.details}
                </Typography>
              </CardContent>
            </CardActionArea>
          </CardItemContainer>
          <Typography sx={{ marginTop: "10px" }}>
            {userCoupon.users_coupon.coupon_name}を使用します
          </Typography>
          <Typography sx={{ marginTop: "10px" }}>
            この画面を店員にお見せください
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCheckClose}
            sx={{ fontSize: "12px" }}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={handleUseCoupon}
            autoFocus
            sx={{ fontSize: "12px" }}
          >
            使用済にする
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CouponList: React.FC = () => {
  const [coupons, setCoupons] = useState<UserCoupon[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const api = useAPI();

  const fetchCoupons = useCallback(async () => {
    try {
      const response: UserCoupon[] = await api.getWithAction(
        "user-coupon",
        "index",
        {
          withCredentials: true,
        }
      );
      setCoupons(response);
    } catch (error: any) {
      console.error("クーポン取得エラー:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCouponUsed = useCallback(() => {
    fetchCoupons();
  }, [fetchCoupons]);

  useEffect(() => {
    fetchCoupons();
  }, [fetchCoupons]);

  return (
    <Container>
      <TextBox>使用できるクーポンの一覧です</TextBox>
      {coupons.length > 0 ? (
        <Grid2 container spacing={2} sx={{ p: 1, width: "80%" }}>
          {coupons.map((user_coupon) => (
            <CouponItem
              userCoupon={user_coupon}
              key={user_coupon.id}
              onCouponUsed={handleCouponUsed}
            />
          ))}
        </Grid2>
      ) : (
        <SubTextBox>使用できるクーポンがありません</SubTextBox>
      )}
      <BottomBox></BottomBox>
    </Container>
  );
};

export default CouponList;
