import React, { useEffect, useState } from "react";
import {
  Container,
  TextBox,
  CardItemContainer,
  BottomBox,
  CustomDialogTitle,
  SubTextBox,
} from "./styles";
import {
  Box,
  Button,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  Grid2,
  Typography,
} from "@mui/material";
import axios from "../service/AxiosBaseService";
import { User, ResponseDataType } from "./MyPage";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../service/api";

export interface Coupon {
  id: number;
  coupon_name: string;
  discount: string;
  description: string;
  details: string;
  image: string;
  use_point: number;
}

const CouponItem = ({
  coupon,
  user,
}: {
  coupon: Coupon;
  user: User | null;
}) => {
  const [open, setOpen] = useState(false);
  const [checkOpen, setCheckOpen] = useState(false);
  const [exchangeComplete, setExchangeComplete] = useState(false);
  const isDisabled = user ? coupon.use_point > user.point : true;
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickCheckOpen = () => {
    handleClose();
    setCheckOpen(true);
  };

  const handleCheckClose = () => {
    setCheckOpen(false);
  };

  const handleExchangeCoupon = async (couponId: any) => {
    try {
      const response = await axios.post(
        "/user-coupon",
        {
          users_coupon: couponId,
        },
        { withCredentials: true }
      );

      setExchangeComplete(true);
      handleCheckClose();
      navigate("/coupon");
    } catch (error: any) {
      console.error(
        "クーポン交換エラー:",
        error.response?.data?.detail || error.message
      );
      alert(
        `クーポン交換に失敗しました: ${
          error.response?.data?.detail || "不明なエラー"
        }`
      );
    }
  };

  return (
    <>
      <CardItemContainer
        onClick={isDisabled ? undefined : handleClickOpen}
        style={{
          opacity: isDisabled ? 0.5 : 1,
          pointerEvents: isDisabled ? "none" : "auto",
        }}
      >
        <CardMedia component="img" image={coupon.image} alt="Paella dish" />
        <CardActionArea sx={{ display: "flex" }}>
          <CardContent>
            <Typography variant="h6" component="div">
              {coupon.coupon_name}
            </Typography>
            <Typography variant="body2">割引: {coupon.discount}</Typography>
            <Typography variant="body2">
              使用するポイント: {coupon.use_point}P
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {coupon.details}
            </Typography>
          </CardContent>
        </CardActionArea>
      </CardItemContainer>

      <Dialog open={open} onClose={handleClose}>
        <CustomDialogTitle>クーポンの交換</CustomDialogTitle>
        <DialogContent sx={{ margin: "0px 20px" }}>
          <CardItemContainer>
            <CardMedia component="img" image={coupon.image} alt="Paella dish" />
            <CardActionArea sx={{ display: "flex" }}>
              <CardContent>
                <Typography variant="h6" component="div">
                  {coupon.coupon_name}
                </Typography>
                <Typography variant="body2">割引: {coupon.discount}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {coupon.details}
                </Typography>
              </CardContent>
            </CardActionArea>
          </CardItemContainer>
          <Box
            sx={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <Typography variant="h4">{coupon.use_point}P</Typography>
            <Typography variant="h6">を消費して</Typography>
          </Box>
          <Typography>{coupon.coupon_name}を交換しますか？</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            キャンセル
          </Button>
          {!isDisabled ? (
            <Button
              variant="contained"
              onClick={() => handleClickCheckOpen()}
              autoFocus
            >
              交換
            </Button>
          ) : (
            <></>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={checkOpen} onClose={handleClose}>
        <CustomDialogTitle>クーポンの交換</CustomDialogTitle>
        <DialogContent sx={{ margin: "0px 20px", padding: 0 }}>
          <Box
            sx={{
              marginTop: "10px",
              padding: "0px 10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" sx={{ marginRight: "10px" }}>
              所持P
            </Typography>
            <Typography variant="h4">{user?.point}P</Typography>
          </Box>
          <Box
            sx={{
              marginTop: "10px",
              padding: "0px 10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
            }}
          >
            <Typography variant="h6" sx={{ marginRight: "10px" }}>
              消費P
            </Typography>
            <Typography variant="h4">{coupon.use_point}P</Typography>
          </Box>
          <Box
            sx={{
              marginTop: "10px",
              padding: "0px 10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" sx={{ marginRight: "10px" }}>
              残りP
            </Typography>
            <Typography variant="h4">
              {(user?.point || 0) - coupon.use_point}P
            </Typography>
          </Box>
          <Typography sx={{ marginTop: "10px" }}>
            {coupon.coupon_name}を交換しますか？
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCheckClose}>
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={() => handleExchangeCoupon(coupon.id)}
            autoFocus
          >
            交換
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={exchangeComplete}
        onClose={() => setExchangeComplete(false)}
      >
        <DialogContent sx={{ margin: "0px 20px" }}>
          <CardItemContainer>
            <CardMedia component="img" image={coupon.image} alt="Paella dish" />
            <CardActionArea sx={{ display: "flex" }}>
              <CardContent>
                <Typography variant="h6" component="div">
                  {coupon.coupon_name}
                </Typography>
                <Typography variant="body2">割引: {coupon.discount}</Typography>
                <Typography variant="body2">
                  使用するポイント: {coupon.use_point}P
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {coupon.details}
                </Typography>
              </CardContent>
            </CardActionArea>
          </CardItemContainer>
        </DialogContent>
        <CustomDialogTitle sx={{ marginTop: "0px" }}>
          {coupon.coupon_name}を交換しました。
        </CustomDialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setExchangeComplete(false)}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const GetCouponList: React.FC = () => {
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const api = useAPI();

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await axios.get("/coupon");
        setCoupons(response.data);
        const userResponse = await api.getWithAction<ResponseDataType>(
          "users",
          "one",
          {
            withCredentials: true,
          }
        );
        setUser(userResponse.user);
      } catch (error: any) {
        console.error("クーポン取得エラー:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCoupons();
  }, []);

  return (
    <Container>
      <TextBox>交換できるクーポンの一覧です</TextBox>
      <SubTextBox sx={{ padding: "10px 10px 10px 10px" }}>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          所持ポイント
        </Typography>

        <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
          {user?.point} P
        </Typography>
      </SubTextBox>
      <Grid2 container spacing={2} sx={{ p: 1, width: "80%" }}>
        {coupons.map((coupon) => (
          <CouponItem coupon={coupon} user={user} key={coupon.id} />
        ))}
      </Grid2>
      <BottomBox></BottomBox>
    </Container>
  );
};

export default GetCouponList;
