import React, { useState, useEffect, ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import {
  Container,
  StyledForm,
  StyledFormControl,
  TextBox,
  SendButton,
  FormBox,
  BottomBox,
} from "./styles";

import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "../service/AxiosBaseService";
import { useAPI } from "../service/api";

interface EditProfileFormProps {}

export interface UserDataType {
  id: string;
  name: string;
  name_kana: string;
  gender: "male" | "female" | "other";
  birth_month: string | null;
  phone_number: string;
  point: number;
  create_date: string;
  image_url: string;
  line_id: string;
  line_name: string;
}
export interface ResponseDataType {
  user: UserDataType;
}

export const updateUserData = async (
  userData: UserDataType
): Promise<UserDataType> => {
  const response = await axios.put<UserDataType>(
    `/users/${userData.id}`,
    userData
  );
  console.log(response.data);
  return response.data;
};

const EditProfileForm: React.FC<EditProfileFormProps> = () => {
  const api = useAPI();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserDataType>({
    id: "",
    name: "",
    name_kana: "",
    gender: "male",
    birth_month: "1月",
    phone_number: "",
    point: 0,
    create_date: "",
    image_url: "",
    line_id: "",
    line_name: "",
  });

  const { data: initialUserData, isLoading } = useQuery<UserDataType, Error>({
    queryKey: ["userData"],
    queryFn: async () => {
      try {
        const response = await api.getWithAction<ResponseDataType>(
          "users",
          "one",
          {
            withCredentials: true,
          }
        );
        console.log("response", response);
        return response.user;
      } catch (error) {
        console.error("ユーザーデータ取得エラー:", error);
        throw error;
      }
    },
  });

  useEffect(() => {
    if (initialUserData) {
      setUserData(initialUserData);
    }
  }, [initialUserData]);

  const mutation = useMutation<UserDataType, Error, UserDataType>({
    mutationFn: updateUserData,
    onSuccess: (data: UserDataType) => {
      console.log("会員情報更新成功:", data);
      navigate("/mypage");
    },
    onError: (error: Error) => {
      console.error("会員情報更新エラー:", error);
      alert("更新に失敗しました。");
      alert(error.message);
    },
  });

  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const target = event.target as
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement;
    const value = target.value;

    setUserData({
      ...userData,
      [target.name as keyof UserDataType]:
        target.name === "phone_number" ? value.replace(/\D/g, "") : value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutation.mutate(userData);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <TextBox>会員情報編集</TextBox>
      <FormBox>
        <StyledForm onSubmit={handleSubmit}>
          <TextField
            id="name"
            name="name"
            label="名前"
            variant="outlined"
            value={userData.name}
            required
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            id="name_kana"
            name="name_kana"
            label="フリガナ"
            variant="outlined"
            value={userData.name_kana}
            required
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <StyledFormControl variant="outlined" sx={{ mb: 2 }}>
            <InputLabel id="gender-label">性別</InputLabel>
            <Select
              labelId="gender-label"
              id="gender"
              name="gender"
              value={userData.gender}
              onChange={(event: any) => handleChange(event)}
              label="性別"
            >
              <MenuItem value="male">男性</MenuItem>
              <MenuItem value="female">女性</MenuItem>
              <MenuItem value="other">その他</MenuItem>
            </Select>
          </StyledFormControl>
          <StyledFormControl variant="outlined" sx={{ mb: 2 }}>
            <InputLabel id="birthMonth-label">お誕生月</InputLabel>
            <Select
              labelId="birthMonth-label"
              id="birthMonth"
              name="birth_month"
              value={userData.birth_month}
              onChange={(event: any) => handleChange(event)}
              label="お誕生月"
            >
              {Array.from({ length: 12 }, (_, i) => (
                <MenuItem key={i + 1} value={`${i + 1}月`}>
                  {i + 1}月
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <TextField
            id="phone_number"
            name="phone_number"
            label="電話番号"
            variant="outlined"
            value={userData.phone_number}
            onChange={handleChange}
            required
            sx={{ mb: 2 }}
          />

          <SendButton type="submit" variant="contained" color="primary">
            更新
          </SendButton>
        </StyledForm>
      </FormBox>
      <BottomBox></BottomBox>
    </Container>
  );
};

export default EditProfileForm;
