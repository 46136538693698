import { styled } from "@mui/material/styles";
import {
  FormControl,
  Box,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  Card,
  DialogTitle,
} from "@mui/material";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
`;

export const BottomBox = styled(Box)`
  height: 100px;
  background-color: #f0f0f0;
`;

export const BottomNav = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 34px;
  background-color: black;
  color: white;
`;

export const BottomNavItem = styled(BottomNavigationAction)`
  color: #a0a0a0;

  position: relative; // 線を表示するための位置調整

  &.Mui-selected {
    color: white;
    background-color: black;

    &::after {
      // 選択時に線を表示
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px; // 線の表示位置を調整
      width: 100%;
      height: 2px; // 線の太さを調整
      background-color: #e8380d;
    }
  }
`;

export const StyledForm = styled("form")`
  & > * {
    margin: 10px 0px !important;
    width: 90%;
  }
`;

export const StyledFormControl = styled(FormControl)`
  margin: 10px 0px !important;
  width: 90%;
`;

export const TextBox = styled(Box)`
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 0px;
  margin-top: 20px;
  margin-bottom: 10px;
  background: linear-gradient(
    90deg,
    #e8795d 0%,
    #e8795d 3%,
    white 3%,
    white 97%,
    #e8795d 97%,
    #e8795d 100%
  );
  color: #404040;
  // border-radius: 10px;
`;

export const SubTitleTextBox = styled(Box)`
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 0px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-bottom: 5px solid #e8795d;
  background-color: white;
  color: #404040;
  // border-radius: 10px;
`;

export const SubTextBox = styled(Box)`
  width: 75%;
  font-size: 15px;
  padding: 10px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: white;
  color: #404040;
  border-radius: 10px;
`;

export const FormBox = styled(Box)`
  width: 80%;
  font-size: 15px;
  padding: 10px 10px 20px;
  margin-bottom: 10px;
  background-color: #ffffff;
  color: #404040;
  border-radius: 10px;
`;

export const SendButton = styled(Button)`
  margin-top: 20px;
  width: 50%;
`;

export const AddButton = styled(Button)`
  position: absolute;
  position: fixed;
  bottom: 100px;
  right: 20px;
  height: 64px;
  border-radius: 50%;
  background-color: #1976d2;
  color: white;
  font-size: 24px;
  border: none;
  cursor: pointer;
`;

export const StampContainer = styled("div")<{ numRows: number }>`
  margin-top: 20px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(5, 40px);
  grid-template-rows: repeat(${(props) => props.numRows}, 40px);
  background-color: white;
  border-left: 15px solid #e8380d;
  border-right: 15px solid #e8380d;
  padding: 30px 15px;
  border-radius: 5px;
`;

export const Stamp = styled("div")<{ isStamped: boolean; number: number }>`
  border-radius: 50%;
  background-color: #efefef;
  // border: 1px solid #ccc;
  // padding-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StampNumber = styled("span")`
  font-size: 20px;
  color: #e8380d;
`;

export const CardItemContainer = styled(Card)`
  width: 100%;
  background: linear-gradient(
    90deg,
    #e8380d 0%,
    #e8380d 7%,
    white 7%,
    white 100%
  );
  // display: flex;
`;

export const ListButton = styled(Button)`
  margin-top: 30px;
  // margin-right: 100px;
  float: left;
  color: black;
  background-color: white;
  // display: flex;
`;

export const ListCard = styled("div")`
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
`;

export const StampImage = styled("img")`
  width: 30px;
  height: 30px;
`;

export const CustomDialogTitle = styled(DialogTitle)`
  font-size: 15px;
  font-weight: bold;
  padding: 10px 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  background: linear-gradient(
    90deg,
    #e8380d 0%,
    #e8380d 3%,
    #f0f0f0 3%,
    #f0f0f0 100%
  );
`;
