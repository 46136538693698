import React, { useEffect, useState } from "react";
import {
  Container,
  TextBox,
  AddButton,
  Stamp,
  StampContainer,
  StampNumber,
  ListButton,
  BottomBox,
  SubTextBox,
} from "./styles";
import AddIcon from "@mui/icons-material/Add";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";
import liff from "@line/liff";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../service/AxiosBaseService";
import { useAPI } from "../service/api";
import dayjs from "dayjs";

interface StampCardModel {
  id: number;
  stamp_card_name: string;
  max_stamps: number;
  stamp_image: string;
}

interface UserStampCard {
  id: number;
  stamp_card: StampCardModel;
  is_active: number;
  user: number;
  current_stamps: number;
  end_at: number;
}

interface QRcode {
  code_name: string;
  code: string;
  is_using: boolean;
}

const StampCard: React.FC = () => {
  const api = useAPI();
  const [scannedValue, setScannedValue] = useState("");
  const [stamps, setStamps] = useState<boolean[]>([]);
  const [stampImages, setStampImages] = useState<{ imageUrl: string }[]>([]);
  const location = useLocation();
  const [code, setCode] = useState<string | undefined>();
  const [activeUserStampCard, setActiveUserStampCard] =
    useState<UserStampCard | null>(null);
  const navigate = useNavigate();
  const numColumns = 5;
  const [numRows, setNumRows] = useState(0);

  useEffect(() => {
    const initializeLiffAndFetchData = async () => {
      try {
        const params: any = {};

        if (location.state) {
          params.card_id = location.state.toast.status;
        }
        const response = await api.getWithAction<UserStampCard>(
          "user-stamp-card",
          "one",

          { params: params, withCredentials: true }
        );
        if (!response || !response.stamp_card) {
          navigate("/stamp/index");
          return;
        }

        setActiveUserStampCard(response || null);

        const initialStamps = Array(response.stamp_card.max_stamps).fill(false);
        for (let i = 0; i < response.current_stamps; i++) {
          initialStamps[i] = true;
        }
        setStamps(initialStamps);

        const newStampImages = Array.isArray(response.stamp_card.stamp_image)
          ? response.stamp_card.stamp_image.map((imageUrl: any) => ({
              imageUrl,
            }))
          : [{ imageUrl: response.stamp_card.stamp_image }];

        setStampImages(newStampImages);

        setNumRows(Math.ceil(response.stamp_card.max_stamps / numColumns));
      } catch (error: any) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          navigate("/stamp/index");
        } else {
          console.error("スタンプカード取得エラー:", error);
        }
      }
    };

    initializeLiffAndFetchData();
  }, []);

  const getQR = async () => {
    const response: QRcode[] = await api.get(`qrcode`);

    setCode(response[0].code);

    liff
      .scanCodeV2()
      .then((result) => {
        if (result.value !== null) {
          setScannedValue(result.value);
          if (result.value === code) {
            getStamp();
          }
        } else {
          console.error("QRコードの読み取りに失敗しました。");
          setScannedValue("読み取り失敗");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getStamp = async () => {
    try {
      await axios
        .post(
          "/user-stamp-history",
          {
            user_stamp_card: activeUserStampCard?.id,
          },
          { withCredentials: true }
        )
        .then((response) => {
          console.log("Success:", response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      if (activeUserStampCard) {
        const nextStampIndex = stamps.findIndex((stamp) => !stamp);
        if (nextStampIndex !== -1) {
          const newStamps = [...stamps];
          newStamps[nextStampIndex] = true;
          setStamps(newStamps);

          setActiveUserStampCard({
            ...activeUserStampCard,
            current_stamps: activeUserStampCard.current_stamps + 1,
          });
        }
      }
    } catch (error: any) {
      console.error("スタンプ付与エラー:", error);
    }
  };

  const currentStamps = activeUserStampCard?.current_stamps ?? 0;
  const maxStamps = activeUserStampCard?.stamp_card?.max_stamps ?? 0;

  const handleGetStamp = () => {
    if (currentStamps < maxStamps) {
      // getStamp();
      getQR();
    } else {
      handleNavigate();
    }
  };

  const handleNavigate = () => {
    navigate("/stamp/index");
  };

  return (
    <Container>
      <TextBox>スタンプカード</TextBox>

      <StampContainer numRows={numRows}>
        {stamps.map((isStamped, i) => (
          <Stamp key={i} isStamped={isStamped} number={i + 1}>
            {isStamped && (
              <img
                src={stampImages[i % stampImages.length].imageUrl}
                alt={`Stamp ${i + 1}`}
                style={{ width: "30px", height: "30px" }}
              />
            )}
            {!isStamped && <StampNumber>{i + 1}</StampNumber>}
          </Stamp>
        ))}
      </StampContainer>
      {currentStamps < maxStamps ? (
        <>
          <SubTextBox
            sx={{ padding: "10px 10px 20px 20px", marginTop: "30px" }}
            textAlign={"left"}
          >
            <Typography variant="body1">
              右下の
              <Typography component="span" color="primary">
                青いボタン
              </Typography>
              を押して
            </Typography>
            <Typography variant="body1">
              QRコードを読み込んでください
            </Typography>
            <Typography variant="body1">
              スタンプを押すとポイントがたまります
            </Typography>
            <Typography variant="body1">
              ポイントはクーポンと交換できます
            </Typography>
            <Typography variant="body1">スタンプは一日一回限りです</Typography>
          </SubTextBox>
        </>
      ) : (
        <>
          <SubTextBox
            sx={{ padding: "10px 10px 20px 20px", marginTop: "30px" }}
            textAlign={"left"}
          >
            <Typography variant="body1">
              {activeUserStampCard?.end_at &&
                `★  完了日：${dayjs(activeUserStampCard?.end_at).format(
                  "YYYY-MM-DD"
                )}`}
            </Typography>
          </SubTextBox>
        </>
      )}
      <Box sx={{ width: "80%" }}>
        <ListButton
          startIcon={<MenuIcon />}
          onClick={handleNavigate}
          sx={{ marginTop: "5px" }}
        >
          スタンプカード一覧
        </ListButton>
      </Box>
      {currentStamps < maxStamps ? (
        <AddButton onClick={handleGetStamp}>
          <AddIcon sx={{ width: "40px", height: "40px" }} />
        </AddButton>
      ) : (
        <AddButton onClick={handleGetStamp}>
          <UTurnLeftIcon
            sx={{ width: "40px", height: "40px", transform: "rotate(90deg)" }}
          />
        </AddButton>
      )}

      <BottomBox></BottomBox>
    </Container>
  );
};

export default StampCard;
