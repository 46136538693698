import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import {
  Container,
  StyledForm,
  StyledFormControl,
  TextBox,
  SendButton,
  FormBox,
  BottomBox,
} from "./styles";
import { RegisterUserResponse, UserDataType } from "../service/api";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import axios from "../service/AxiosBaseService";
import liff from "@line/liff";

interface RegistrationFormProps {}

const RegistrationForm: React.FC<RegistrationFormProps> = () => {
  const [name, setName] = useState<string>("");
  const [name_kana, setFurigana] = useState<string>("");
  type Gender = "male" | "female" | "other";
  const [gender, setGender] = useState<Gender>("male");
  const [birth_month, setBirthMonth] = useState<string>("1月");
  const [phone_number, setPhoneNumber] = useState<string>("");

  const navigate = useNavigate();

  const mutation = useMutation<RegisterUserResponse, Error, UserDataType>({
    mutationFn: async (userData) => {
      try {
        const response = await axios.post("/users", userData, {
          withCredentials: true,
        });
        return response.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          alert("認証エラーが発生しました。再度ログインしてください。");
          liff.logout();
          liff.login();

          throw new Error("認証エラー");
        } else if (error.response) {
          throw new Error(
            `APIエラー: ${error.response.status} ${error.response.data.detail}`
          );
        } else {
          throw new Error("ネットワークエラー");
        }
      }
    },
    onSuccess: (data: RegisterUserResponse) => {
      console.log("会員登録成功:", data);

      window.location.reload();
    },

    onError: (error: Error) => {
      console.error("会員登録エラー:", error);
      alert("登録に失敗しました。 " + error.message);
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const userData: UserDataType = {
      name,
      name_kana,
      gender,
      birth_month,
      phone_number,
    };

    mutation.mutate(userData);
  };

  return (
    <Container>
      <TextBox>
        登録いただくと会員価格で
        <br />
        お召し上がりいただけます
      </TextBox>
      <FormBox>
        <StyledForm onSubmit={handleSubmit}>
          <TextField
            id="name"
            label="名前"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            id="furigana"
            label="フリガナ"
            variant="outlined"
            value={name_kana}
            onChange={(e) => setFurigana(e.target.value)}
            required
          />
          <StyledFormControl variant="outlined">
            <InputLabel id="gender-label">性別</InputLabel>
            <Select
              labelId="gender-label"
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value as Gender)}
              label="性別"
            >
              <MenuItem value="male">男性</MenuItem>
              <MenuItem value="female">女性</MenuItem>
              <MenuItem value="other">その他</MenuItem>
            </Select>
          </StyledFormControl>
          <StyledFormControl variant="outlined">
            <InputLabel id="birthMonth-label">お誕生月</InputLabel>
            <Select
              labelId="birthMonth-label"
              id="birthMonth"
              value={birth_month}
              onChange={(e) => setBirthMonth(e.target.value)}
              label="お誕生月"
            >
              {Array.from({ length: 12 }, (_, i) => (
                <MenuItem key={i + 1} value={`${i + 1}月`}>
                  {i + 1}月
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <TextField
            id="phoneNumber"
            label="電話番号 (数字のみ)"
            type="number"
            variant="outlined"
            value={phone_number}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />

          <SendButton type="submit" variant="contained" color="primary">
            送信
          </SendButton>
        </StyledForm>
      </FormBox>
      <BottomBox></BottomBox>
    </Container>
  );
};

export default RegistrationForm;
