import React, { useEffect, useState } from "react";
import {
  BottomBox,
  Container,
  ListButton,
  SubTextBox,
  TextBox,
} from "./styles";
import { Grid2, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../App";
import { useAPI } from "../service/api";
export interface User {
  id: string;
  name: string;
  name_kana: string;
  gender: "male" | "female" | "other";
  birth_month: string | null;
  phone_number: string;
  point: number;
  create_date: string;
  image_url: string;
  line_id: string;
  line_name: string;
}

export interface ResponseDataType {
  user: User;
}

const MyPage: React.FC = () => {
  const [user, setUser] = useState<User>();
  const api = useAPI();

  useEffect(() => {
    const getUser = async () => {
      const response = await api.getWithAction<ResponseDataType>(
        "users",
        "one",
        {
          withCredentials: true,
        }
      );
      setUser(response.user);
    };
    getUser();
  }, []);
  const navigate = useNavigate();
  const handleClickEdit = () => {
    navigate("/mypage/edit");
  };
  const handleClickCoupon = () => {
    navigate("/mypage/coupon");
  };
  return (
    <Container>
      <TextBox>{user?.name}様会員情報</TextBox>
      <SubTextBox sx={{ padding: "40px 10px 20px 10px" }}>
        <Typography sx={{ fontSize: "40px", fontWeight: "bold" }}>
          {user?.point} P
        </Typography>
        <ListButton
          startIcon={<ConfirmationNumberOutlinedIcon color="success" />}
          variant="outlined"
          color="success"
          sx={{
            marginTop: "30px",
            float: "right",
            pl: 2,
          }}
          onClick={handleClickCoupon}
        >
          クーポンを獲得する
        </ListButton>
      </SubTextBox>
      <SubTextBox sx={{ padding: "30px 10px 20px 10px", marginTop: "20px" }}>
        <Grid2 container spacing={2}>
          <Grid2 size={6} sx={{ textAlign: "Right" }}>
            お名前：
          </Grid2>
          <Grid2 size={6}>{user?.name}</Grid2>
          <Grid2 size={6} sx={{ textAlign: "Right" }}>
            フリガナ：
          </Grid2>
          <Grid2 size={6}>{user?.name_kana}</Grid2>
          <Grid2 size={6} sx={{ textAlign: "Right" }}>
            誕生月：
          </Grid2>
          <Grid2 size={6}>{user?.birth_month}</Grid2>
        </Grid2>
        <ListButton
          startIcon={<EditIcon color="success" />}
          variant="outlined"
          color="success"
          sx={{
            marginTop: "20px",
            float: "right",
            pl: 2,
          }}
          onClick={handleClickEdit}
        >
          会員情報編集
        </ListButton>
      </SubTextBox>
      <BottomBox></BottomBox>
    </Container>
  );
};

export default MyPage;
