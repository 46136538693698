import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import liff from "@line/liff";
import { LiffMockPlugin } from "@line/liff-mock";
import "./App.css";
import RegistrationForm from "./components/RegistrationForm";
import CouponList from "./components/CouponList";
import StampCard from "./components/StampCard";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import ApprovalOutlinedIcon from "@mui/icons-material/ApprovalOutlined";
import PersonIcon from "@mui/icons-material/Person";
import { BottomNav, BottomNavItem } from "./components/styles";
import MyPage from "./components/MyPage";
import StampCardList from "./components/StampCardList";
import EditProfileForm from "./components/EditProfileForm";
import GetCouponList from "./components/GetCouponList";
import axios from "./service/AxiosBaseService";

interface AuthContextType {
  token: string | null;
  userData: any | null;
  loading: boolean;
  error: any | null;
}

const AuthContext = createContext<AuthContextType | null>(null);

function App() {
  const [token, setAccessToken] = useState<string | null>(null);
  const [values, setValues] = useState(0);
  const location = useLocation();

  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);

  const authContextValue = useMemo(
    () => ({
      token,
      userData,
      loading,
      error,
    }),
    [token, userData, loading, error]
  );

  useEffect(() => {
    const liffConfig = {
      liffId: "2006621539-MBkzjYaE",
    };

    liff
      .init(liffConfig)
      .then(() => {
        if (!liff.isLoggedIn()) {
          console.log("loginerror");
          return liff.login();
        }
      })
      .then(() => {
        const accessToken = liff.getAccessToken();
        if (accessToken) {
          setAccessToken(accessToken);

          axios
            .post("/users/login", { accessToken }, { withCredentials: true })
            .then((response) => {
              if (response.data.exists) {
                setUserData(response.data.user);
                setIsAuthenticated(true);
                setLoading(false);
                navigate("/mypage");
              } else {
                setLoading(false);
                navigate("/");
              }
            })
            .catch((error) => {
              setLoading(false);
              console.error("Login Error:", error);
              navigate("/");
            });
        } else {
          console.warn("Access token not found.");
          navigate("/");
        }
      });
  }, []);

  useEffect(() => {
    if (location.pathname === "/mypage") {
      setValue(0);
    } else if (location.pathname === "/coupon") {
      setValue(1);
    } else if (location.pathname === "/stamp") {
      setValue(2);
    }
  }, [location.pathname]);

  return (
    <AuthContext.Provider value={authContextValue}>
      <div className="App">
        <Routes>
          <Route path="/" element={<RegistrationForm />} />{" "}
          {isAuthenticated ? (
            <>
              <Route path="/mypage" element={<MyPage />} />
              <Route path="/mypage/edit" element={<EditProfileForm />} />
              <Route path="/mypage/coupon" element={<GetCouponList />} />
              <Route path="/coupon" element={<CouponList />} />
              <Route path="/stamp" element={<StampCard />} />
              <Route path="/stamp/index" element={<StampCardList />} />
            </>
          ) : (
            <Route path="/" element={<RegistrationForm />} />
          )}
        </Routes>

        <BottomNav
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavItem
            label="マイページ"
            onClick={() => {
              setValue(0);
              navigate("/mypage");
            }}
            icon={<PersonIcon />}
          />
          <BottomNavItem
            label="クーポン"
            onClick={() => {
              setValue(1);
              navigate("/coupon");
            }}
            icon={<ConfirmationNumberOutlinedIcon />}
          />
          <BottomNavItem
            label="スタンプ"
            onClick={() => {
              setValue(2);
              navigate("/stamp");
            }}
            icon={<ApprovalOutlinedIcon />}
          />
        </BottomNav>
      </div>
    </AuthContext.Provider>
  );
}

export default App;

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
